import React, { useState } from 'react';
import{ FaBars,FaTimes} from 'react-icons/fa';
import {Link} from 'react-scroll'

const NavBar = () => {

    const[nav,setNav]=useState(false);

    const links =[
        {
            id:1,
            link:'Home',
        },

        {
            id:2,
            link:'About',
        },

        {
            id:4,
            link:'Projects',
        },

        {
            id:5,
            link:'Experience',
        },

        {
            id:6,
            link:'Contact',
        },
    ]

  return (
    <div className="flex justify-center items-center w-full h-20 px-4 text-white bg-black"> 
    
        <ul className='hidden md:flex '>

            {links.map(({id,link}) => (
                <li 
                key={id}
                className='px-4 cursor-pointer capitalize font-medium text-gray-500 hover:text-white duration-300'>
                <Link to={link} smooth duration={500}>{link}</Link>
                </li>
            ))}
        </ul>

        <div onClick={()=> setNav(!nav)} className='cursor-pointer pr-4 z-10 text-gray-500 md:hidden '> 
            {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
        </div>

        {nav && (
            <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-gray-500'>  
            {links.map(({id,link}) => (
                    <li 
                    key={id}
                    className='px-4 cursor-pointer capitalize py-6 text-4xl'>
                     <Link onclick={()=>setNav(!nav)} to={link} smooth duration={500}>{link}</Link>
                    </li>
                ))}
            </ul>
        )}

        
    </div>
  );
};

export default NavBar;